/* eslint-disable no-unused-vars */
import { httpRequest } from '@/hosting/http-request';
import { KeapEnv } from '@/hosting/keap-env';

/**
 * Returns public form details
 *
 * @param {String} slug The smart-forms-api slug
 * @return {Promise<object>} The form details
 */
export const getPublicFormInfo = (slug, inf_contact_key = '') => {
    let baseURL = `${KeapEnv.smartFormsApiUrl}/public/formPages/${slug}`;

    baseURL = inf_contact_key ? `${baseURL}?inf_contact_key=${inf_contact_key}` : baseURL;

    return httpRequest({
        url : baseURL,
    });
};

/**
 * Submits a smart form to the server.
 *
 * @param formId The primary key of the smart-form
 * @param postData The form submission postData
 * @param recaptchaToken The recaptcha token
 * @return {Promise<*>}
 */
export async function submitSmartForm({ formId, postData, recaptchaToken }) {
    const config = {
        headers: {
            'x-disable-redirect': true,
            'x-keap-recaptcha-token': recaptchaToken,
        },
    };

    return httpRequest({
        url: `${KeapEnv.smartFormsApiUrl}/public/forms/${formId}/submissions?useEnterpriseRecaptcha=true`,
        method: 'POST',
        data: postData,
        config,
    });
}

/**
 * Get a form field's input option values.
 *
 * @param formId The primary key of the smart-form
 * @param inputOptionsType The type of input options that the returned values must match
 * @param value Selected value
 * @return {Promise<*>}
 */
export async function formInputOptionValues(formId, inputOptionsType, value) {
    return await httpRequest({
        url: `${KeapEnv.smartFormsApiUrl}/public/forms/${formId}/inputOptionsType/${inputOptionsType}/options/${value}`,
    });
}
