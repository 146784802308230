import amplitude from '@/hosting/keap-analytics/keap-track-amplitude';
import reporting from '@/hosting/keap-analytics/keap-track-reporting';

import { findParentScopes, isPreviewMode } from '@/hosting/keap-hosting-util';
import { KeapEnv } from '@/hosting/keap-env';
import { KEAP_TAGS } from '@/hosting/hosting.constants';
import once from 'lodash/once';

/**
 * A root-level element to declare shared attributes, like appId, environment, and version (the version of this library)
 *
 * <keap-hosting app-id="sb1235" env="staging" version="1.0.22">
 *    ...
 * </keap-hosting>
 */
export class KeapHosting extends HTMLElement {
    connectedCallback() {
        let env = this.getAttribute('env');

        if (env) {
            amplitude.setUserProperty('Environment', env);
            KeapEnv.keapEnv = env;
        }

        let appName = this.getAttribute('app-id');

        if (appName) {
            amplitude.setUserProperty('App Name', appName);
            KeapEnv.appId = appName;
        }

        let isPreview = this.getAttribute('is-preview');

        if (isPreview === 'true') {
            KeapEnv.isPreview = isPreview;
        }
    }
}

/**
 * Indicates that a certain analytics event should be recorded when this element is loaded.
 *
 * <keap-event name="page-viewed">
 *     ...
 * </keap-event>
 */
export class KeapEvent extends HTMLElement {
    connectedCallback() {
        const scopes = findParentScopes(this);
        const name = this.getAttribute('name');

        amplitude.logEvent(name, { ...scopes });

        if (!isPreviewMode(this)) {
            // noinspection JSIgnoredPromiseFromCall
            reporting.logEvent(name, { ...scopes });
        }
    }
}

export const registerKeapEventElements = once(() => {
    customElements.define(KEAP_TAGS.keapScope, class extends HTMLElement {
    });
    customElements.define(KEAP_TAGS.keapEvent, KeapEvent);
});

export const registerKeapHostingElement = once(() => {
    customElements.define(KEAP_TAGS.keapHosting, KeapHosting);
});
