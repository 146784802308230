import { formInspector1_0_x } from './form-inspector-1.0.22';
import { formInspector_Default } from './form-inspector-default';

const DEFAULT_INSPECTOR = formInspector_Default();
const versionedInspectors = [
    formInspector1_0_x(),
    DEFAULT_INSPECTOR,
];

/**
 * Inspects a form to retrieve all form fields.  Because this may change by version, we provide a versioned field retrieval strategy.
 *
 * The form should have an attribute data-keap-form-version that will be used to lookup the appropriate strategy. This allows us to
 * change the form structure/markup (and fix bugs) without breaking older forms.
 *
 * @param {HTMLFormElement} form
 * @param {KeapFormState} formState
 * @param {string} hostingVersion
 */
export function inspectForm(form, formState, { hostingVersion }) {
    const inspector = versionedInspectors.find(({ applies }) => applies(hostingVersion))
        ?? DEFAULT_INSPECTOR;

    return inspector.inspectForm(form, formState);
}
