/**
 * Decode URI string, also converting + symbols to spaces
 *
 * @param {string} s string to decode
 * @returns {string} decoded string
 * @example
 * decode("Bought%20keyword)
 * => "Bought keyword"
 */
export function urlDecode(s) {
    return decodeURIComponent(s?.replace(/\+/g, ' '));
}

/**
 * Attempts to parse a URL.  If unsuccessful, will return null instead of throwing an exception.
 * @param urlValue
 * @return {null|URL}
 */
export function tryParseURL(urlValue) {
    try {
        return new URL(urlValue);
    } catch (e) {
        return null;
    }
}

/**
 * Get host domain of url
 * @param  {URL} url - href of page
 * @return {String} base hostname of page
 *
 * @example
 *  getDomainBase('https://subdomain.my-site.com/')
 *  > my-site.com
 */
export function getDomainBase(url) {
    const host = url.hostname ?? '';

    return host.split('.').slice(-2).join('.');
}

/**
 * Remove TLD from domain string.
 *
 * @param  {String} baseDomain - host name of site
 * @return {String}
 * @example
 *  trimTld('google.com')
 *  > google
 */
export function trimTld(baseDomain) {
    const arr = baseDomain.split('.');

    return (arr.length > 1) ? arr.slice(0, -1).join('.') : baseDomain;
}

