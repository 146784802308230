import {
    CAMPAIGN_PARAM_MAP,
    GOOGLE_DOMAIN_TOKEN,
    SEARCH_ENGINES,
} from '@/hosting/keap-analytics/original-source/original-source.constants';
import { parseUrlQueryString } from '@/shared/querystring-utils';
import { isEmptyValue } from '@/hosting/keap-hosting-util';
import { getDomainBase, trimTld, urlDecode } from '@/shared/url-utils';

/**
 * @typedef AttributionData
 *
 * @property {string} campaign
 * @property {string} source
 * @property {string} medium
 * @property {string} content
 * @property {string} term
 * @property {string} landingPage
 * @property {string} referrer
 *
 * @return {AttributionData}
 */
export function AttributionData({ campaign, source, medium, content, term, landingPage, referrer, ...others } = {}) {
    const values = { campaign, source, medium, content, term, landingPage, referrer, ...others };

    return Object.entries(values).reduce((prev, [k, v])=> {
        if (!isEmptyValue(v)) prev[k] = v;

        return prev;
    }, {});
}

/**
 * Checks a given url and parses referrer data.
 *
 * @param  {URL} [referURL] - (optional) referring URL
 * @param  {URL} [currURL] - (optional) the current url
 * @return {AttributionData}
 */
export function parseReferrerAndUtmParams(referURL, currURL) {

    // default referral data
    let attrData = AttributionData();

    if (currURL?.hostname) {
        attrData.landingPage = currURL?.toString();
    }

    // Add raw ref url if external
    if (isExternalReferrer(referURL, currURL)) {
        attrData.referrer = referURL.toString();
    }

    const domainInfo = parseDomain(referURL, currURL);

    // Read referrer URI and infer source
    if (domainInfo && Object.keys(domainInfo).length) {
        attrData = Object.assign({}, attrData, domainInfo);
    }

    // Read URI queryParams and use set utm queryParams
    const queryParams = parseUrlQueryString(currURL?.toString());

    const utmParams = Object.entries(queryParams).reduce((acc, [key, value]) => {
        // match utm queryParams & dclid (display) & gclid (cpc)

        if (key.match(/^utm_/)) {
            acc[`${key.replace(/^utm_/, '')}`] = value;
        } else if (CAMPAIGN_PARAM_MAP[key]) {
            key = CAMPAIGN_PARAM_MAP[key];
            acc[key] = value;
        }

        // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters
        // dclid - cpc Cost-Per-Thousand Impressions
        // gclid - cpc Cost per Click
        if (key.match(/^([dg])clid/)) {
            acc.source = acc.source || GOOGLE_DOMAIN_TOKEN;
            acc.medium = acc.medium || ((queryParams.gclid) ? 'cpc' : 'cpm');
            acc[key] = queryParams[key];
        }

        return acc;
    }, {});

    return Object.assign({}, attrData, utmParams);
}


/**
 * Client side domain parser for determining marketing data.  Handles a few things:
 *
 * - If the referrer is a known search engine:
 *      `medium=organic,source=[searchEngine],term=[searchTerm]`
 *
 * - If the referrer is same-site
 *      `medium=internal, source=referrer.hostname`
 *
 * - If the referrer is external
 *      `medium='referral', source=referrer.hostname`
 *
 * @param  {URL} referURL - referer url
 * @param  {URL} origURL - original url
 * @return {AttributionData | boolean}
 */
function parseDomain(referURL, origURL) {
    if (!referURL ) return false;

    let referringDomain = getDomainBase(referURL);

    // Shim for the billion google search engines
    if (referURL.hostname?.includes(GOOGLE_DOMAIN_TOKEN)) {
        referringDomain = GOOGLE_DOMAIN_TOKEN;
    }

    // If is search engine, extract the term/medium
    if (SEARCH_ENGINES[referringDomain]) {
        const [queryParam, searchEngineName] = SEARCH_ENGINES[referringDomain];
        const sp = referURL.searchParams;
        const term = sp.has(queryParam) ? sp.get(queryParam) : null;

        return AttributionData({
            source: searchEngineName ?? trimTld(referringDomain),
            medium: 'organic',
            term: term ? urlDecode(term) : null,
        });
    }

    // Default
    const medium = isExternalReferrer(referURL, origURL) ? 'referral' : 'internal';

    return AttributionData({
        source: referURL?.hostname,
        medium,
    });
}

/**
 * @param {URL | null | undefined} referURL
 * @param {URL|null} currURL
 * @returns {boolean | undefined}
 */
function isExternalReferrer(referURL, currURL) {
    if (referURL && currURL) {
        return referURL.hostname !== currURL.hostname;
    }

    return false;
}


