export const GOOGLE_DOMAIN_TOKEN = 'google';

export const ORIGINAL_SOURCE_KEY = 'user_original_source';

export const UTMZ_COOKIE_KEY = '__utmz';

export const SEARCH_ENGINES = {
    'daum.net': ['q'],
    'eniro.se': ['search_word'],
    'naver.com': ['query'],
    'yahoo.com': ['p'],
    'msn.com': ['q'],
    'aol.com': ['q'],
    'ask.com': ['q'],
    'baidu.com': ['wd'],
    'yandex.com': ['text'],
    'rambler.ru': ['words'],
    'google': ['q'],
    'bing.com': ['q', 'live'],
};

export const CAMPAIGN_PARAM_MAP = {
    'utmcsr': 'source',
    'utmcmd': 'medium',
    'utmccn': 'campaign',
    'utmcct': 'content',
    'utmctr': 'term',
    'utmgclid': 'gclid', // Google Click ID from autotagged PPC
    'utmdclid': 'dclid', // Display Click Identifier.
};

