import { mapEntries } from '@/hosting/keap-hosting-util';
import camelCaseLodash from 'lodash/camelCase';

export const camelCaseKeys = (input) => mapEntries(input, (k, v) => [camelCaseLodash(k), v]);

export function camelCase(input) {
    switch (typeof input) {
    case 'string':
        return camelCaseLodash(input);
    case 'object':
        return camelCaseKeys(input);
    default:
        return input;
    }
}
