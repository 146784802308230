import { getItem, setItem } from '@/hosting/storage';
import { EnvironmentKind, KeapEnvConfig } from '@/shared/keap-env-config';
import { KEAP_EVENT_NAMES } from '@/hosting/keap-analytics/keap-analytics.constants';
import { calculateOriginalSource, hasOriginalSource } from '@/hosting/keap-analytics/original-source';
import amplitude from '@/hosting/keap-analytics/keap-track-amplitude';
import reporting from '@/hosting/keap-analytics/keap-track-reporting';

const KEAP_ENV_KEY = 'keap_env';
const APP_ID_KEY = 'app_id';

/**
 * Store/retrieve information about the keap-web environment.  These values are persisted in local storage so they can
 * be used across multiple page views or interactions.
 *
 * @property appId The customer's appId to be used for interactions on this page
 * @property keapEnv One of [EnvironmentKind] values, indicating what keap-web environment this page should interact with
 */
export const KeapEnv = {
    get keapEnv() {
        return (getItem(KEAP_ENV_KEY) ?? EnvironmentKind.integration);
    },

    set keapEnv(keapEnv) {
        if (keapEnv) setItem(KEAP_ENV_KEY, keapEnv);
    },

    /**
     * Whether this page is in preview mode
     */
    isPreview: false,

    get appId() {
        return getItem(APP_ID_KEY);
    },

    set appId(appId) {
        if (appId) {
            setItem(APP_ID_KEY, appId);

            if (!hasOriginalSource()) {
                const calculatedSource = calculateOriginalSource();

                if (calculatedSource) {
                    // Record an event to analytics systems
                    amplitude.logEvent(KEAP_EVENT_NAMES.sourceChanged, calculatedSource);

                    // noinspection JSIgnoredPromiseFromCall
                    reporting.logEvent(KEAP_EVENT_NAMES.sourceChanged, calculatedSource);
                }
            }
        }
    },

    get smartFormsApiUrl() {
        return (KeapEnvConfig[this.keapEnv] ?? KeapEnvConfig.default).SMART_FORMS_API_URL;
    },

    get templateMgmtApiUrl() {
        return (KeapEnvConfig[this.keapEnv] ?? KeapEnvConfig.default).TEMPLATE_MANAGEMENT_API_URL;
    },

    get eventReportingUrl() {
        return (KeapEnvConfig[this.keapEnv] ?? KeapEnvConfig.default).EVENT_API_URL;
    },
};
