import inMemoryStorage from './in-memory';

/**
 * Checks whether local storage is permitted/available for this browser.  If local storage is not permitted, then
 * the values are kept in memory and will be lost when the page is refreshed.
 * @type {boolean}
 */
const useSessionStorage = (() => {
    try {
        if (typeof sessionStorage === 'undefined' || typeof JSON === 'undefined') {
            return false;
        }
        // test for safari
        sessionStorage.setItem('_t_', '1');
        sessionStorage .removeItem('_t_');
    } catch (err) {
        return false;
    }

    return true;
})();


function getStorage() {
    return useSessionStorage ? sessionStorage : inMemoryStorage;
}

export function getItem(key) {
    const value = getStorage().getItem(toStorageKey(key));

    return JSON.parse(value);
}

export function setItem(key, value) {

    if (value == null) {
        getStorage().removeItem(toStorageKey(key));
    } else {
        const serialized = JSON.stringify(value);

        getStorage().setItem(toStorageKey(key), serialized);
    }

    return value;
}

function toStorageKey(key) {
    return `__ka_${key}`;
}
