/**
 * Simple promise-based request method to avoid pulling in an entire library
 */
export function httpRequest({
    url,
    data,
    method = 'GET',
    config: { headers = {} } = {},
}) {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();

        request.onerror = (err)=> reject(err);
        request.onabort = reject;
        request.ontimeout = reject;

        request.onload = () => {
            const { responseText } = request;

            if (request.status !== 200) {
                reject(['Failed to upload!', request.responseText]);
                /// should we put the logs back?
            } else {
                resolve(JSON.parse(responseText));
            }
        };
        request.open(method, url);
        request.setRequestHeader('Content-Type', 'application/json');
        request.setRequestHeader('Accept', 'application/json');
        Object.entries(headers ?? {})
            .forEach(([k, v]) => request.setRequestHeader(k, `${v}`));

        try {
            if (method === 'GET') {
                request.send();
            } else {
                request.send(data ? JSON.stringify(data) : undefined);
            }
        } catch (e) {
            reject(e);
        }
    });
}
