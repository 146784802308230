/**
 * Placeholder for when we're ready to upload events.  In a separate file to make it easier to mock.
 * @param eventName
 * @param eventData
 * @return {Promise<void>}
 */

import { KEAP_EVENT_NAMES } from '@/hosting/keap-analytics/keap-analytics.constants';
import { httpRequest } from '@/hosting/http-request';
import { KeapEnv } from '@/hosting/keap-env';

const EVENT_PAGE_VIEWED = 'pageViewed';
const EVENT_VISITOR_IDENTIFIED = 'visitorIdentified';

const EVENT_NAME_TO_ENDPOINT_MAP = {
    [KEAP_EVENT_NAMES.pageViewed]: EVENT_PAGE_VIEWED,
    [KEAP_EVENT_NAMES.sourceChanged]: EVENT_VISITOR_IDENTIFIED,
};

export async function uploadReportingEvent(eventName, eventData = {}) {
    const mappedEventName = EVENT_NAME_TO_ENDPOINT_MAP[eventName];
    const payload = mapEventProperties(mappedEventName, eventData);

    if (!mappedEventName || !payload) {
        console.warn(`Cant find event ${eventName}`);
    }

    const { tenantId } = eventData;

    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    };

    await httpRequest({
        url: `${KeapEnv.eventReportingUrl}/tenant/${tenantId}/tracking/${mappedEventName}`,
        method: 'POST',
        data: payload,
        config,
    });

    // uncomment once the endpoint doesn't throw 401
}

function mapEventProperties(eventName, eventData) {
    switch (eventName) {
    case EVENT_PAGE_VIEWED: {
        const {
            visitId,
            visitorId,
            timestamp,
            eventData: { marketingSiteSlug: siteId, marketingPageSlug: pageId } = {},
        } = eventData;

        return {
            pageId, siteId, visitId, visitorId, timestamp,
        };
    }

    case EVENT_VISITOR_IDENTIFIED: {
        const {
            visitorId,
            timestamp,
        } = eventData;

        return { visitorId, timestamp };
    }
    default:
        return null;
    }
}
