/* eslint-disable no-console */
import { documentLoaded } from '@/shared/dom-utils';
import { isPreviewMode } from '@/hosting/keap-hosting-util';

/**
 * We deploy our funnels with raw html links because then they can be crawled, but we also do some progressive enhancement
 * as well, for a couple of purposes:
 *
 * 1. To disable these links when the page is rendered in preview mode
 * 2. To clean up the links, since we append some extra information that may be used in the future to resolve broken slugs
 */
export async function processKeapLinks() {
    await documentLoaded();

    document.body.querySelectorAll('a[data-keap-link-type]').forEach((linkElement)=> {
        const { href } = linkElement;

        // We append primary keys to the end of links.  This helps us in case the slug ever changes. Remove for now since it
        // will just make the url look ugly
        linkElement.setAttribute('href', href.toString().replace(/#.*/, ''));

        linkElement.onclick = () => {
            // todo: wait for any on-page operations to complete, like stats?
            if (isPreviewMode(linkElement)) {
                // Do nothing, links don't work in previews.  At some point it might be nice to ajax in the page
                // without doing a full page transition
                console.log('Preview link clicked: ', linkElement.href);

                return false;
            } else {
                // This will cause the link to be followed, like normal
                return true;
            }
        };
    });

    document.body.querySelectorAll('[success-redirect]').forEach((element)=> {
        const href = element.getAttribute('success-redirect');

        element.setAttribute('success-redirect', href.toString());
    });

}
