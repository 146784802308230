import amplitude from 'amplitude-js';
import { KEAP_EVENT_NAMES } from '@/hosting/keap-analytics/keap-analytics.constants';
import { properCase } from '@/shared/proper-case';

export default {
    init() {
        if (process.env.AMPLITUDE_V2_KEY
            && process.env.AMPLITUDE_LOGGING !== 'disabled') {
            amplitude.getInstance().init(process.env.AMPLITUDE_V2_KEY);
        }
    },

    /**
     * @param name Event name.  Must exist in KEAP_EVENT_NAMES
     * @param  data event data, if any
     */
    logEvent(name, data = null) {
        if (process.env.AMPLITUDE_LOGGING === 'disabled') {
            return;
        }

        if (!Object.values(KEAP_EVENT_NAMES).includes(name)) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.warn(`"${name}" is not a valid Amplitude Event. Please ensure that all events being sent to Amplitude are approved by your leader, listed in the keap-analytics.constants.js file, and added to the production Amplitude schema.`);
            }

            return;
        }

        amplitude.getInstance().logEvent(properCase(name), properCase(data));
    },

    setUserProperty(propName, value) {
        if (process.env.AMPLITUDE_LOGGING !== 'disabled') {
            const identify = new amplitude.Identify();

            identify.set(properCase(propName), value);
            amplitude.getInstance().identify(identify);
        }
    },
};
