import { KEAP_TAGS } from '@/hosting/hosting.constants';
import { isPreviewMode } from '@/hosting/keap-hosting-util';
import once from 'lodash/once';
import { enhanceKeapForm } from '@/hosting/keap-forms-processing/index';
import { documentLoaded } from '@/shared/dom-utils';
import { FORM_EVENTS } from '@/hosting/keap-forms-processing/keap-forms.constants';

class KeapFormElement extends HTMLElement {
    async connectedCallback() {
        await documentLoaded();
        const previewMode = isPreviewMode(this);

        this.formHandler = enhanceKeapForm(this, { previewMode });
        this.dispatchEvent(new CustomEvent(FORM_EVENTS.formHandlerInitialized, {
            bubbles: true,
            detail: this.formHandler,
        }));
    }
}

export const registerKeapFormElement = once(() =>
    customElements.define(KEAP_TAGS.keapForm, KeapFormElement));
