/* eslint-disable no-console */
import { inspectFormControls } from './form-inspector-shared';
import { KEAP_ATTRIBUTES } from '@/hosting/hosting.constants';


export function formInspector_Default() {

    return {
        /**
         * Field inspector for version = default (unlayer)
         *
         * This field inspector is based on the default form structure provided by unlayer.
         * - Each div within the form element represents a form field.
         * - The name of the field is extracted from the form input names.
         *
         * @param {HTMLFormElement} form
         * @param {KeapFormState} formState
         */
        inspectForm(form, formState) {

            const fieldElements = form.children;

            for (let i = 0; i < fieldElements.length; i++) {
                const fieldElement = fieldElements[i];
                const isRequired = Boolean(fieldElement.getAttribute(KEAP_ATTRIBUTES.formIsRequired));

                inspectFormControls(fieldElement, { isRequired }, formState);
            }
        },

        applies() {
            // This is the fallback and always applies
            return true;
        },
    };

}
