import { pruneEmpty } from '@/hosting/keap-hosting-util';
import { KEAP_EVENT_NAMES } from '@/hosting/keap-analytics/keap-analytics.constants';
import { getItem, setItem } from '@/hosting/storage';
import { v4 } from 'uuid';
import { KeapEnv } from '@/hosting/keap-env';
import { uploadReportingEvent } from '@/hosting/keap-analytics/keap-track-reporting-api';
import { camelCaseKeys } from '@/shared/camel-case';

const USER_PROPERTIES_KEY = 'user_properties';
const VISIT_ID_KEY = 'visit_id';
const VISIT_TS_KEY = 'visit_ts_id';
const VISIT_TIMEOUT_SECONDS = 60 * 30;
const VISITOR_ID_KEY = 'visitor_id';

export default {
    /**
     * Logs an event to the reporting backend for landing pages
     *
     * @param eventName
     * @param {object} eventData
     * @return {Promise<void>}
     */
    async logEvent(eventName, eventData) {
        if (process.env.KEAP_REPORTING_LOGGING === 'disabled') {
            return;
        }

        if (!Object.values(KEAP_EVENT_NAMES).includes(eventName)) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.warn(`"${eventName}" is not a valid Analytics Event. Please ensure that all events being sent to Analytics are approved by your leader, listed in the keap-analytics.constants.js file, and added to the production Analytics schema.`);
            }

            return;
        }

        const visitorId = this.getVisitorId();
        const visitId = this.getVisitId();
        const tenantId = KeapEnv.appId;
        const userProperties = this.getUserProperties();

        this.updateVisitTimestamp();

        const data = camelCaseKeys({
            userData: camelCaseKeys(userProperties),
            eventData: camelCaseKeys(pruneEmpty(eventData)),
            visitId,
            visitorId,
            tenantId,
            timestamp: new Date(),
        });

        await uploadReportingEvent(eventName, data);
    },

    getUserProperties() {
        return getItem(USER_PROPERTIES_KEY) ?? {};
    },

    setUserProperty(key, value) {
        if (process.env.KEAP_REPORTING_LOGGING !== 'disabled') {
            return;
        }
        let userProperties = getItem(USER_PROPERTIES_KEY) ?? {};

        if (!value) {
            delete userProperties[key];
        } else {
            userProperties[key] = value;
        }

        setItem(USER_PROPERTIES_KEY, userProperties);
        this.updateVisitTimestamp();
    },

    updateVisitTimestamp() {
        setItem(VISIT_TS_KEY, Date.now());
    },

    getVisitId() {
        let visitId = getItem(VISIT_ID_KEY);
        let timestamp = getItem(VISIT_TS_KEY);

        if (timestamp) {
            const elapsed = (Date.now() - timestamp) / 1000;

            // If the session timed out, generate a new visit id
            if (elapsed > VISIT_TIMEOUT_SECONDS) {
                visitId = v4().toString();
            }
        }

        if (!visitId) {
            visitId = v4().toString();
            setItem(VISIT_ID_KEY, visitId);
        }
        this.updateVisitTimestamp();

        return visitId;
    },

    getVisitorId() {
        let id = getItem(VISITOR_ID_KEY);

        if (!id) {
            id = v4().toString();
            setItem(VISITOR_ID_KEY, id);
        }

        return id;
    },
};
