/**
 * keap-track uses custom elements to declarative analytics scope and operations on a static hosted page.
 *
 * Instead of relying on injecting imperative tracking scripts into pages for common things like logging page views
 * or tracking appId, pageId, tenantId, etc, we are wrapping the page content in tags that declare attributes
 * and events that should be logged.
 *
 * In addition to declaring actions, these tags also provide a natural hierarchical scope that will be appended to events to
 * assist in reporting.  For example, the scope for a form event might include:
 *
 * ```const scope = {
 *     'app-id': 'bobs_garage',
 *     'marketing-site-slug': 'holiday_special',
 *     'marketing-page-slug': 'squeeze_page',
 *     'form-slug': '55431112333211',
 * }```
 *
 * By using hierarchical tags, the form doesn't have to store all those properties directly on the form tag, but can
 * derive them from the page.
 *
 * Another advantage of using declarative actions is that it makes the actual processing code more opaque, and easier
 * to upgrade or change, because we don't have hard-coded javascript snippets directly into our hosted page.
 *
 * Example of markup:
 * ```
 * <keap-hosting app-id="bobs-garage" env="prod">
 *   <keap-scope marketing-site-id="1234" marketing-site-name="My Site">
 *     <keap-scope marketing-page-id="get-ripped" marketing-page-name="Get RIPPED">
 *       <keap-event name="marketing-page-viewed">
 *           /// Rest of page goes here
 *       </keap-event>
 *     </keap-scope>
 *   </keap-scope>
 * </keap-hosting>
 */

export { registerKeapHostingElement, registerKeapEventElements }  from './keap-track-elements';
