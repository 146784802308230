import {
    CoercingFieldType,
    FieldType,
    ValidatingFieldType,
} from '@/hosting/keap-forms-processing/field-types/field-type';
import { patterns } from '@infusionsoft/vue-utils';

const EMAIL_REGEX = RegExp(patterns.email);

export const EmailFieldType = ValidatingFieldType({
    type: 'email',
    validator: (input) => EMAIL_REGEX.test(String(input).toLowerCase()),
});

export const DateFieldType = CoercingFieldType({
    type: 'date',
    converter: (dateStr) => {
        if ((dateStr ?? '').split('-').length === 3) {
            return dateStr;
        } else {
            throw Error(`Expecting date, but got ${dateStr}`);
        }
    },
});

export const BooleanFieldType = CoercingFieldType({ type: 'boolean', converter: (input) => input !== 'false' });
export const CurrencyFieldType = CoercingFieldType({ type: 'currency', converter: Number.parseFloat });
export const NumberFieldType = CoercingFieldType({ type: 'number', converter: Number.parseFloat });
export const TextFieldType = FieldType({ type: 'text' });
export const TelephoneFieldType = FieldType({ type: 'tel' });
export const WholeNumberFieldType = CoercingFieldType({ type: 'whole-number', converter: Number.parseInt });
