import { urlDecode } from './url-utils';

export const QUERY_ITEM_RE = /([^&=]+)=?([^&]*)/g;
export const QUERY_EMPTY_ARRAY = '[]';

/**
 * Parse url parameters into javascript object
 *
 * @param  {string} url String url to parse.
 * @return {object} parsed url parameters
 */
export function parseUrlQueryString(url) {
    const p = url?.match(/\?(.*)/) ?? [];

    const qs = (p[1] ?? '').split('#')[0];

    return parseQueryString(qs);
}

/**
 * Parses request params as an object, also allowing for jsonapi style parameter parsing:
 *
 * https://jsonapi.org/format/
 *
 * @param {string} query
 * @return {object}
 */
function parseQueryString(query) {
    query = query ?? '';
    let params = {};
    let temp;

    while ((temp = QUERY_ITEM_RE.exec(query)) !== null) {
        let key = urlDecode(temp[1]);
        let value = urlDecode(temp[2]);

        if (key.substring(key.length - 2) === QUERY_EMPTY_ARRAY) {
            key = key.substring(0, key.length - 2);
            params[key] = [
                ...(params[key] ?? []),
                value,
            ];
        } else {
            const prevProp = params[key];

            // If we encounter a value multiple times, or if the previous value is already an array,
            // then treat the value(s) as an array instead of overwriting the old value.
            if (Array.isArray(prevProp)) {
                if (value) prevProp.push(value);
            } else if (prevProp && value) {
                params[key] = [prevProp, value];
            } else {
                params[key] = (value === '') ? true : value;
            }
        }
    }

    const values = Object.entries(params)
        .reduce((prev, [prop, value]) => {
            const arr = prop.split('[');

            if (arr.length > 1) {
                const keyPath = arr.map((x) => x.replace(/[?[\]\\ ]/g, ''));

                deepAssign(prev, keyPath, value);
            } else {
                prev[prop] = value;
            }

            return prev;
        }, {});

    Object.defineProperty(values, 'get', {
        enumerable: false,
        value: (path)=> deepGet(values, path),
    });

    Object.defineProperty(values, 'getBool', {
        enumerable: false,
        value: (path)=> booleanOf(values.get(path)),
    });

    Object.defineProperty(values, 'getArray', {
        enumerable: false,
        value: (path)=> arrayOf(values.get(path)),
    });

    return values;
}

function arrayOf(value) {
    return Array.isArray(value) ? value : [value];
}

function booleanOf(value) {
    if (!value) return false;

    switch (value?.toString()?.toLowerCase()) {
    case 'false': return false;
    case '0': return false;
    case 'no': return false;
    default: return true;
    }
}

function deepAssign(obj, keyPath, value) {
    const lastKeyIndex = keyPath.length - 1;

    for (let i = 0; i < lastKeyIndex; i++) {
        const key = keyPath[i];

        if (!(key in obj)) {
            obj[key] = {};
        }
        obj = obj[key];
    }
    obj[keyPath[lastKeyIndex]] = value;
}

function deepGet(obj, keyPath) {
    if (!Array.isArray(keyPath)) {
        keyPath = keyPath.split('.');
    }

    return keyPath.reduce((prev, path) => {
        if (!prev || !path) return prev;

        return prev[path];
    }, obj);
}


