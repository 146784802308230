/**
 * Custom elements for handling tracking.  see ./keap-track/keap-track-elements
 */
export const KEAP_TAGS = {
    // A keap-event tag indicates that an event should be logged when this element is loaded into the DOM.
    keapEvent: 'keap-event',

    // Keap scope tags append values to an event's scope
    keapScope: 'keap-scope',

    // A root element that contains information like the tenantId
    keapHosting: 'keap-hosting',

    // A form processed by keap
    keapForm: 'keap-form',
};

/**
 * Custom data props and attributes used by keap-hosting
 */
export const KEAP_ATTRIBUTES = {
    trackEvent: 'data-track-event',
    trackContext: 'data-track-context',
    trackId: 'data-track-id',
    trackName: 'data-track-name',
    trackValue: 'data-track-value',
    tenantId: 'app-id',
    keapHostingVersion: 'version',
    keapEnv: 'data-keap-env',
    formIsProcessed: 'is-processed',
    formSlug: 'slug',
    formSuccessRedirect: 'success-redirect',
    formSuccessTarget: 'success-target',
    formIsRequired: 'data-keap-is-required',
    formVersion: 'data-keap-form-version',
    isPreview: 'is-preview',
};
