import { loadExternalScript } from '@/hosting/keap-hosting-util';

export function RecaptchaHelper(recaptchaKey) {
    let resolve, reject;
    const loaded = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });

    const init = () => {
        loadExternalScript('recaptcha-script',
            `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaKey}`,
            {
                // eslint-disable-next-line no-undef
                onload: () => grecaptcha.enterprise.ready(() => resolve(grecaptcha)),
                onerror: reject,
            });

        return loaded;
    };

    init();

    return {
        async getRecaptchaToken(action) {
            const recaptcha = await init();

            return await recaptcha.enterprise.execute(recaptchaKey, { action });
        },
    };
}
