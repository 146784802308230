import { getItem, setItem } from '@/hosting/storage';
import { getCookie } from '@/shared/cookie-utils';
import { tryParseURL } from '@/shared/url-utils';
import { parsePipeString } from '@/shared/pipe-string';
import { parseReferrerAndUtmParams } from './parse-referrer-and-utm';
import merge from 'lodash/merge';
import {
    CAMPAIGN_PARAM_MAP,
    ORIGINAL_SOURCE_KEY,
    UTMZ_COOKIE_KEY,
} from '@/hosting/keap-analytics/original-source/original-source.constants';


export function hasOriginalSource() {
    const originalSource = getItem(ORIGINAL_SOURCE_KEY);

    return originalSource && Object.keys(originalSource).length > 0;
}

/**
 * Attempts to extract attribution values from the visitor's request, including:
 *
 * Source (utm_source)	Identifies which site sent the traffic, and is a required parameter
 * Medium (utm_medium)	Identifies what type of link was used, such as cost per click or email.
 * Campaign (utm_campaign)	Identifies a specific product promotion or strategic campaign.
 * Term (utm_term)	Identifies search terms.
 * Content (utm_content)	Identifies what specifically was clicked to bring the u
 * Landing Page (the first page accessed for this visit)
 * Referring URL (if the referrer is an external domain)
 * Referrer domain
 * Search engine
 *
 * Attribution values are determined by:
 *
 * - Examining the referral URL to determine if the referral was external or internal
 * - If the referral was external, attempt to extract keywords or search terms for known search engines.
 * - Examining __utmz cookie
 * - Examining the request parameters to look for known utm_* values
 * - Looking for other known request parameters, such as gclid or dclid (Adwords auto-tagging parameters)
 * - Capturing the initial URL (landing page) that was accessed for this visitor
 *
 * Once these values are extracted, they are passed along to amplitude or our reporting backend as user properties.  These
 * values should only be set once per visit.  The result is captured in localStorage and will not be analyzed again.
 *
 * @param {String} referrer Provided value of referrer.  If none is provided, uses document.referrer
 * @param {URL} href The href for this document. If none is provided, uses window.location.href
 * @return {object}
 */
export function calculateOriginalSource({ referrer, href } = {}) {

    const currentURL = href ?? window.location;
    const referURL = tryParseURL(referrer ?? document.referrer);

    // 1. try __utmz cookie
    const utmzCookie = getCookie(UTMZ_COOKIE_KEY);
    const attributionValues = {};

    if (utmzCookie) {
        // These cookies are formatted using urchin
        const parsedCookie = parsePipeString(utmzCookie);

        if (parsedCookie) {
            Object.assign(attributionValues, convertKeys(parsedCookie));
        }
    }

    // 2. Then try referrer url and utm params from the URL
    const refData = parseReferrerAndUtmParams(referURL, currentURL);

    const merged = convertKeys(merge(attributionValues, refData));

    if (Object.keys(merged).length > 0) {
        return setItem(ORIGINAL_SOURCE_KEY, merged);
    } else {
        return null;
    }
}

function convertKeys(obj) {
    return Object.entries(obj).reduce((acc, [key, value])=> {
        if (value) {
            if (CAMPAIGN_PARAM_MAP[key]) {
                acc[`${CAMPAIGN_PARAM_MAP[key]}`] = value;
            } else {
                acc[key] = value;
            }
        }

        return acc;
    }, {});

}

