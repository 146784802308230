/**
 * @typedef ValidationResult
 * @property {*} value
 * @property [string] errors
 */

/**
 * Receives changes from the raw html form controls, and performs type coercion and validation.
 *
 * @typedef FieldType
 * @property {string} type
 * @property {function(input): ValidationResult} convertAndValidate A function that converts the string into the correct type, and also performs validation
 *
 * @return FieldType
 */
export function FieldType({ type, convertAndValidate } = {}) {
    return {
        type,
        convertAndValidate: convertAndValidate ?? ((value) => ({ value, errors: [] })),
    };
}

/**
 * A field type that doesn't do any type-coercion, but does validate that the string input meets certain requirements.
 *
 * @param type The field type
 * @param validate Validation function, returns true/false
 * @return {ValidationResult}
 */
export function ValidatingFieldType({ type, validator } = {}) {
    return FieldType({
        type,
        convertAndValidate: (value) =>
            validator(value) ? { value } : {
                value, errors: [`invalid.${type}`],
            },
    });
}

/**
 * A field type that doesn't perform validation of the input - it only coerces the data from string into another
 * data type
 *
 * @param type The field type
 * @param converter Function to convert from string to the desired data type
 * @return {ValidationResult}
 */
export function CoercingFieldType({ type, converter } = {}) {
    return FieldType({
        type,
        convertAndValidate: (value) => {
            try {
                const converted = converter(value);

                return {
                    value: converted,
                    errors: [],
                };
            } catch (e) {
                return {
                    value,
                    errors: [`invalid.${type}`],
                };
            }
        },
    });
}


