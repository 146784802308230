import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

const properCaseStr = (input) => startCase(camelCase(input));

const properCaseKeys = (input) => Object.entries(input).reduce((prev, [k, v]) => {
    prev[properCaseStr(k)] = v;

    return prev;
}, {});

export function properCase(input) {
    switch (typeof input) {
    case 'string':
        return properCaseStr(input);
    case 'object':
        return properCaseKeys(input);
    default:
        return input;
    }
}
