import { urlDecode } from '@/shared/url-utils';

/**
 * Parses pipe-separated values into an object.  Pipe-separated strings are used in urchin tracker module (utm) cookies.
 *
 * @param  {String} cookie - utm cookie value
 * @return {Object} - parsed object of cookie
 *
 * @example
 *
 * parsePipeString('438.1531.1.1.utmcsr=(direct)|utmccn=(direct)|utmcmd=(none)')
*  parsePipeString('16602.15.1.1.utmcsr=google|utmccn=(organic)|utmcmd=organic|utmctr=(not%20provided)')
 */
export function parsePipeString(cookie) {

    const keyValues = (cookie) ? cookie.split('|') : [];

    return keyValues.reduce((acc, curr, i) => {
        const val = keyValues[i].split('=');
        const key = val[0].split('.').pop();

        acc[key] = urlDecode(val[1]);

        return acc;
    }, {});
}
