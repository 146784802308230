import amp from './keap-track-amplitude';
import rep from './keap-track-reporting';

export const amplitude = amp;
export const reporting = rep;

export function initializeAnalytics() {
    amplitude.init();
}

