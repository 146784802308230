/**
 * These types should line up with the possible environment types in keap-web
 */
export const EnvironmentKind = {
    development: 'development', production: 'production', integration: 'integration', staging: 'staging' };

export const KeapEnvConfig = {
    [EnvironmentKind.production]: {
        SMART_FORMS_API_URL: 'https://forms.keapapis.com/api/v1',
        TEMPLATE_MANAGEMENT_API_URL: 'https://template-mgmt.keapapis.com/v1',
        EVENT_API_URL: 'https://template-data.keapapis.com/v1',
        EMBED_FORM_URL: 'https://keap.app',
    },
    [EnvironmentKind.development]: {
        SMART_FORMS_API_URL: 'https://forms.intg.keapapis.com/api/v1',
        TEMPLATE_MANAGEMENT_API_URL: 'https://template-mgmt.intg.keapapis.com/v1',
        EVENT_API_URL: 'https://template-data.intg.keapapis.com/v1',
        EMBED_FORM_URL: 'http://localhost:10239',
    },
    [EnvironmentKind.integration]: {
        SMART_FORMS_API_URL: 'https://forms.intg.keapapis.com/api/v1',
        TEMPLATE_MANAGEMENT_API_URL: 'https://template-mgmt.intg.keapapis.com/v1',
        EVENT_API_URL: 'https://template-data.intg.keapapis.com/v1',
        EMBED_FORM_URL: 'https://integration.keap.app',
    },
    [EnvironmentKind.staging]: {
        SMART_FORMS_API_URL: 'https://forms.stge.keapapis.com/api/v1',
        TEMPLATE_MANAGEMENT_API_URL: 'https://template-mgmt.stge.keapapis.com/v1',
        EVENT_API_URL: 'https://template-data.stge.keapapis.com/v1',
        EMBED_FORM_URL: 'https://staging.keap.app',
    },
};

KeapEnvConfig.default = KeapEnvConfig[EnvironmentKind.integration];
