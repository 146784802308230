import { FORM_EVENTS } from './keap-forms.constants';
import { KeapPublicFormHandler } from './keap-form-handler';

export { registerKeapFormElement } from '@/hosting/keap-forms-processing/keap-form-element';

/**
 * Enhances a single form by:
 *
 * - Loading recaptcha for the form
 * - Locating fields/field groups within the form
 * - Attaching event listeners to each form control
 * - Passing up field changes to the central form instance
 * - Intercepting the form submit handler, performing validation on inputs,
 * - Sending the data as a JSON payload to smart-forms-api
 *
 * @param {HTMLElement} keapFormElement
 * @param {boolean} previewMode
 */
export function enhanceKeapForm(keapFormElement, { previewMode = false }) {
    const htmlForm = keapFormElement.getElementsByTagName('form').item(0);

    if (!htmlForm) {
        console.warn('Unable to process form!');

        return;
    }
    htmlForm.setAttribute('novalidate', 'novalidate');
    const formButtonState = getFormButtonState(htmlForm);

    const formHandler = new KeapPublicFormHandler(keapFormElement, htmlForm);
    const formState = formHandler.formState;

    formHandler.registerFields();
    formHandler.formState.initFormValues();


    htmlForm.onsubmit = function (e) {
        e?.preventDefault();

        formButtonState.onStart();

        // Validate the form
        const { values, errors = [] } = formHandler.validate();

        // Submit the form if valid, otherwise dispatch up a validation failed event
        if (errors.length > 0) {
            formHandler.dispatchEvent(FORM_EVENTS.validationFailed, { formState, errors });
            formButtonState.onEnd();
        } else if (!previewMode) {
            formHandler.submitForm(values)
                .finally(formButtonState.onEnd)
                .then((result) => {
                    if (result?.success) {
                        formHandler.isCompleted = true;
                        formHandler.handleCompletion();
                    }
                });
        } else {
            formButtonState.onEnd();
        }
    };
    formHandler.dispatchEvent(FORM_EVENTS.formProcessed, { formHandler });

    return formHandler;
}

/**
 * Handles disabling and re-enabling buttons
 *
 * @param {HTMLElement} formElement
 */
function getFormButtonState(formElement) {
    const buttons = [...formElement.querySelectorAll('input[type="submit"], input[type="button"], button')];

    return {
        /**
         * Disables form controls during submission
         */
        onStart() {
            buttons.forEach((button) => {
                button.disabled = true;
                button.classList.add('disabled');
            });
        },

        /**
         * Enables form controls during submission
         */
        onEnd() {
            buttons.forEach((button) => {
                button.disabled = false;
                button.classList.remove('disabled');
            });
        },
    };
}
