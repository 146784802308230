
export const FORM_EVENTS = {
    fieldValueChanged: 'keapFieldValueChanged',
    validationFailed: 'keapValidationFailed',
    formSubmitStarted: 'keapFormSubmitStarted',
    formHandlerInitialized: 'keapFormHandlerInitialized',
    formSubmitError: 'keapFormSubmitError',
    formSubmitCompleted: 'keapFormSubmitCompleted',
    formProcessed: 'keapFormProcessed',
    formInspectionComplete: 'keapFormInspectionComplete',
};

export const SMART_FORM_DISPLAY_TYPES = {
    HOSTED: 'Hosted',
    IFRAME: 'IFrame',
    IN_APP: 'InApp',
};

export const FORM_SCOPES = {
    marketingSiteSlug: 'marketing-site-slug',
    marketingSiteName: 'marketing-site-name',
    marketingPageSlug: 'marketing-page-slug',
    marketingPageName: 'marketing-page-name',
};

export const LANDING_PAGE_SOURCE_TYPE = 'LandingPage';
export const RECAPTCHA_ACTION_TOKEN = 'customLandingPageFormSubmission';
